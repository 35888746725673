import React from "react";
import { Link } from "react-router-dom";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";

// スタイルを定義
const styles = (theme: Theme) =>
  createStyles({
    titleImage: {
      width: "100%",
      maxWidth: 700,
    },
    root: {
      width: "80%", // 変更前: width: '80%'
      marginLeft: "auto", // 追加
      marginRight: "auto", // 追加
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: theme.spacing(1),
      overflow: "hidden",
      listStyle: "none",
    },
    listItem: {
      padding: "8px 16px", // 各リストアイテムのパディング
      backgroundColor: "#f0f0f0", // 背景色
      margin: "4px 0", // マージン
      borderRadius: "4px", // 角の丸み
    },
    contentContainer: {
      display: "block",
    },
    textContent: {
      // 新しいスタイル
      textAlign: "left",
      flex: 1,
      display: "inline-block", // テキストコンテンツをインラインブロックにします
      //width: "calc(100% - 135px)", // 画像の幅とマージンを差し引いた幅
      width: "90%",
      verticalAlign: "top", // テキストを上揃えにします
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    imgCenter: {
      // 新規追加
      width: "100%",
      margin: "15px", // テキストとの間隔
    },
    imgLeft: {
      // 新規追加
      height: "120px",
      float: "left", // テキストの左側に画像を配置
      marginRight: "15px", // テキストとの間隔
    },
    imgRight: {
      // 新規追加
      height: "120px",
      float: "right", // テキストの右側に画像を配置
      marginLeft: "15px", // テキストとの間隔
    },
    clearBoth: {
      // 新しいスタイル
      clear: "both", // フロートされた要素の影響をクリアします
    },
    customTypography: {
      fontSize: "1.25rem", // ここで望むフォントサイズに調整します。h4とh5の中間のサイズに設定
      fontWeight: "bold", // ここで文字を太くします
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    customButton: {
      backgroundColor: '#4CAF50', // 明るい緑色
      color: 'white',
      '&:hover': {
        backgroundColor: '#45a049', // ホバー時の色を少し濃い緑に
      },
    },
  });

// Propsの型を定義
interface Props extends WithStyles<typeof styles> {}

class Home extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <img
          src="/images/QRparkingLOGOIMG.jpg"
          alt="title"
          className={classes.titleImage}
        />
          <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          <Paper id="hikaku" className={classes.root} elevation={1}>

        <h3>
            QRパーキングとは
            <br />
            QRコードを敷地に掲示するだけで簡単に始められる有料駐車場です
            <br />
            <br />
            時間・終日・月極の有料駐車場の
            <br />
            契約・請求・精算・督促
            <br />
            が自動で行えます
          <br />
          <br />
          QRパーキングで空き地を収益化しましょう！！
        </h3>
        <br />
        <h4>今なら初期費用0円、月額固定料永久に0円</h4>
          <h3>92%の高収益還元率を実現してください</h3>
        </Paper>

          <br />
        <div>
          <Paper className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              重要なお知らせ
            </Typography>
            <br />
            <br />
            <Paper className={classes.root} elevation={1}>
              <div className={classes.contentContainer}>
                <Typography variant="h5" className={classes.textContent}>
                  期間限定！！初回登録料３万円が０円！！
                </Typography>
                <br />
                <Typography component="div" className={classes.textContent}>
                  今なら初回登録料３万円のところキャンペーン期間中のみ無料でご登録いただけます
                  <br />
                  キャンペーンの期間中にぜひ、登録だけでもお済ませください
                  <br />
                  登録後に固定費等は一切かかりません
                  <br />
                  このチャンスをぜひ！
                  <br />
                </Typography>
              </div>
            </Paper>
            <br />
            <br />
          </Paper>
          <br />
          <br />

          <Paper id="hikaku" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              QRパーキングと他の駐車場経営方法との比較
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <Typography variant="h5" className={classes.textContent}>
                機械式コインパーキング借地型
              </Typography>
              <Typography component="div" className={classes.textContent}>
                オーナーが土地をコインパーキング運営者に賃貸します
                <br />
                利点: 運営管理不要
                <br />
                欠点: 駅近くなどの高収益土地でないと契約不可、低収益還元率
              </Typography>
              <br />
              <br />

              <Typography variant="h5" className={classes.textContent}>
                機械式コインパーキング委託型
              </Typography>
              <Typography component="div" className={classes.textContent}>
                オーナーが土地とコインパーキング設備を用意し、運営者に運営委託します
                <br />
                利点: 収益還元率が高い（約30％ 設置コストの償還後）
                <br />
                欠点:
                駅近くなどの高収益土地でないと契約不可、高額な設置コスト（1台40万～80万円）
              </Typography>
              <br />
              <br />

              <Typography variant="h5" className={classes.textContent}>
                QRパーキング
              </Typography>
              <Typography component="div" className={classes.textContent}>
                決済用QRコードを土地に掲示してオーナーが自ら駐車場を運営します
                <br />
                利点:
                初期費用0円、92％の高収益還元率、自由な運営、どんな場所でも運営可能
                <br />
                欠点: 運営管理はオーナーが担当
              </Typography>
              <img
                src="/images/siteimage/Graph1.jpg"
                className={classes.imgCenter}
              />
            </div>
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          </Paper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            className={classes.customButton}
            onClick={() =>
              window.open("/signinorup" , "_blank")
            }
          >
            無料登録の説明にすすむ
          </Button>
          </div>

          <Paper id="tokucho" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              QRパーキングの利便性と特徴
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <Typography variant="h5" className={classes.textContent}>
                QRコード決済の手軽さ
              </Typography>
              <Typography component="div" className={classes.textContent}>
                ・QRコードをスマホで読み取るだけで決済完了！
                <br />
                ・初期費用と設備費が一切不要！
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.textContent}>
                QRパーキングの特徴
              </Typography>
              <Typography component="div" className={classes.textContent}>
                ・簡単登録：すぐに利用開始
                <br />
                ・低コスト：無料でスタート、維持費も無料！
                <br />
                ・高収益：92％の収益還元率
              </Typography>
              <img
                src="/images/siteimage/44ca0d1c-8f2f-452d-b703-d81a5924f3de.webp"
                className={classes.imgRight}
              />
            </div>
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          </Paper>

          <Paper id="start" className={classes.root} elevation={1}>
            <Typography
              variant="h5"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              簡単な始め方　 QRパーキング導入の手順
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <Typography variant="h5" className={classes.textContent}>
                1.利用開始登録（登録料０円）
              </Typography>
              <Typography component="div" className={classes.textContent}>
                たった数分で完了　今すぐ登録→
                <Link to="/signinorup">詳しく見る</Link>
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.textContent}>
                2.料金設定（利用料金０円）
              </Typography>
              <Typography component="div" className={classes.textContent}>
                専用ページで簡単設定（時間、終日、月極）→
                <Link to="/PricingSettings">詳しく見る</Link>
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.textContent}>
                3.看板購入（任意）
              </Typography>
              <Typography component="div" className={classes.textContent}>
                QRコード付きプリントステッカー・看板をオンラインで注文→
                <Link to="/ProductCatalog">
                  購入
                </Link>
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.textContent}>
                4.看板設置
              </Typography>
              <Typography component="div" className={classes.textContent}>
              自立型で簡単に設置できる看板もございます。
              </Typography>
              <br />
              <br />
              <br />
              <Typography variant="h5" className={classes.textContent}>
                設置後は入金を待つだけ！
              </Typography>
              <img
                src="/images/siteimage/QRparkig看板.jpg"
                className={classes.imgLeft}
              />
            </div>
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          </Paper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            className={classes.customButton}
            onClick={() =>
              window.open("/signinorup" , "_blank")
            }
          >
            無料登録の説明にすすむ
          </Button>
          </div>

          <Paper id="AdvantagesDisadvantages" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              QRパーキングのメリット・デメリットまとめ
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <Typography
                variant="h5"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                メリット
              </Typography>
              <Typography component="div" className={classes.textContent}>
                <ul className={classes.root}>
                  <li className={classes.listItem}>
                    コストゼロ：利用登録料0円！月々利用料0円！
                  </li>
                  <li className={classes.listItem}>
                    スピード：最短3日で営業開始 一週間で入金
                  </li>
                  <li className={classes.listItem}>
                    簡単：頭金・審査・ローン不要
                  </li>
                  <li className={classes.listItem}>
                    柔軟：解約金なし！短期間（釣り、海水浴、花火、祭り期間のみ）でもOK！休止再開もスマホから！
                  </li>
                </ul>
              </Typography>
              <Typography
                variant="h5"
                className={`${classes.textContent} ${classes.customTypography}`}
              >
                デメリット
              </Typography>
              <Typography component="div" className={classes.textContent}>
                <ul className={classes.root}>
                <li className={classes.listItem}>
                    クレジットカードおよびスマホ決済（google pay/apple pay）のみ対応
                  </li>
                <li className={classes.listItem}>
                    運営管理：除草や掃除はオーナーが担当（代行サービス有）
                  </li>
                  <li className={classes.listItem}>
                    トラブル対応：未精算や違法駐車の対応はオーナーが担当（代行サービス有）
                  </li>
                  代行サービスについて→
                  <Link to="/AgencyService">詳しく見る</Link>
                </ul>
              </Typography>
            </div>
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          </Paper>

          <Paper id="faq" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              よくある質問
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <div className={classes.textContent}>
              <Typography component="h5">
                  登録に保証金や預り金などは必要ですか？
                </Typography>
                <Typography component="div">
                  いいえ、無料登録を完了したらすぐに運営開始できます
                </Typography>
                <br />
                <Typography component="h5">
                  登録に審査はありますか？
                </Typography>
                <Typography component="div">
                  Stripe社のアカウント登録には身分証明書の真偽や個人情報の真偽に審査がありますが基本的に嘘がなければ通ります
                </Typography>
                <br />
                <Typography component="h5">
                  看板等は購入しなければなりませんか？
                </Typography>
                <Typography component="div">
                  いいえ、もしすでに看板があるなど看板不要な場合はQRコードをダウンロードしてプリンターで防水シートに印刷するだけで運営開始できます
                </Typography>
                <br />
                <Typography component="h5">
                  QRパーキングで区画月極駐車場を運営できますか？
                </Typography>
                <Typography component="div">
                  QRパーキングは駐車場に名前をつけて複数管理できます　区画ごとにQRコードを掲示すれば区画管理可能です
                </Typography>
                <br />
                <Typography component="h5">
                  人の土地や月極駐車場の又貸しでQRパーキングを運営できますか？
                </Typography>
                <Typography component="div">
                  土地所有者（管理者）の許可を得ていれば問題ありません
                </Typography>
                <br />
                <Typography component="h5">
                  paypayや現金決済はできますか？
                </Typography>
                <Typography component="div">
                  いいえ。支払いに対応しているのはクレジットカードおよびスマホ決済（google pay/apple pay）のみです
                </Typography>
                <br />
                <Typography component="h5">
                  確定申告やインボイスなどの税務手続きは？
                </Typography>
                <Typography component="div">
                  税務申告に必要な書類はすべてダウンロードできます
                </Typography>
                <br />
                <Typography component="h5">
                  開業までの具体的な検討事項は？
                </Typography>
                <Typography component="div">
                  チェックリストをご覧ください→
                  <Link to="/CheckList">詳しく見る</Link>
                </Typography>
                <br />

                <Typography component="h5">
                  不法駐車や未払いの対策は？
                </Typography>
                <Typography component="div">
                  料金トラブルについて→
                  <Link to="/Troubleshooting">詳しく見る</Link>
                </Typography>
                <br />

                <Typography component="h5">自主管理はどんな作業？</Typography>
                <Typography component="div">
                  自主管理について→<Link to="/ToDoList">詳しく見る</Link>
                </Typography>
              </div>
              <img
                src="/images/siteimage/7c3425b0-f346-45ad-979e-23017c2084a7.webp"
                className={classes.imgLeft}
              />
            </div>
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          </Paper>

          <Paper id="Userreviews" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              利用者様の声
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <div className={classes.textContent}>
                <Typography component="h5">
                  以下のようなありがたいコメントを多数頂いております
                </Typography>
                <br />
                <br />
                <Typography component="div">
                  「機械式コインパーキングの更新時期に来たので多額の設備投資とローンを検討していましたが、QRパーキングはリスクも少なくいつでも撤退可能なので試してみました。今のところローンも組まず収益率が上がった分丸々増益で満足しています。」
                  - 東京都：内山さん
                  <br />
                  <br />
                  「月極駐車場の自主運営をしていましたが契約や集金の手間が省けてとても助かります！」
                  - 香川県：富川さん
                  <br />
                  <br />
                  「QRパーキングのおかげで、空き地が月々の収益源になりました！」
                  - 千葉県：田中さん
                  <br />
                  <br />
                  「コインパーキングやレンタル倉庫は断られてしまいましたがQRパーキングは始められました！」
                  - 山口県：福光さん
                  <br />
                  <br />
                  「田舎でまったく期待していませんでしたが初期費用もかからず低価格に設定しても赤字にならないので思ったより収益がでてました！」
                  - 山口県：幸田さん
                  <br />
                  <br />
                  「１台しか停められない狭小地でも、初期費用がかからないのでリスクなく始められました。」
                  - 新潟県：佐藤さん
                  <br />
                  <br />
                  「釣りのシーズンの違法駐車がなくなり、その上予想以上の収益が入ってきました！」
                  - 三重県：徳山さん
                  <br />
                  <br />
                  「一年に一度の花火のときだけ人を雇っていましたが無人で一回で看板代の元が取れました！」
                  - 熊本県：杉本さん
                </Typography>
              </div>
              <img
                src="/images/siteimage/86b16304-80b9-48f9-bbc1-a6cb8d3823c1.webp"
                className={classes.imgRight}
              />
            </div>
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}
          </Paper>

          <Paper id="getstartednow" className={classes.root} elevation={1}>
            <Typography variant="h4" className={`${classes.textContent}`}>
              いますぐ
              <br />
              始めましょう！
            </Typography>
            <br />
            <br />
            <br />

            <div className={classes.contentContainer}>
              <div className={classes.textContent}>
                <Typography component="h5">
                  詳しいパンフレットを請求する
                </Typography>
                <Typography component="div">
                  →<Link to="/MailForm/brochurereques">資料請求</Link>
                </Typography>
                <br />
                <br />
                <Typography component="h5">メールで担当者に相談する</Typography>
                <Typography component="div">
                  →<Link to="/MailForm/mailform">メール相談</Link>
                </Typography>
                <br />
                <br />
                <Typography component="h5">利用登録を開始してみる</Typography>
                <Typography component="div">
                  →          <Button
            variant="contained"
            className={classes.customButton}
            onClick={() =>
              window.open("/signinorup" , "_blank")
            }
          >
            無料登録の説明にすすむ
          </Button>

                </Typography>
              </div>
            </div>
            <br />
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
              <div className={classes.contentContainer}>
                <Typography variant="h5" className={classes.textContent}>
                  月額料金のサブスクリプション登録がいよいよ始動！
                </Typography>
                <br />
                <Typography component="div" className={classes.textContent}>
                  オーナーの皆様からご要望が多かった月額駐車料金が
                  <br />
                  前払い方式からサブスクリプション（定額自動支払）方式に変更になります
                  <br />
                  サブスクリプション実施時には皆様にまたご連絡いたします
                </Typography>
              </div>
          </Paper>
          
            <div className={classes.clearBoth}></div> {/* フロートのクリア */}

        </div>
        <Typography variant="subtitle2">
          <Link to="/specifiedcommercialtransactionact">
            特定商取引法に基づく表記
          </Link>
        </Typography>
      </div>
    );
  }
}

// withStyles HOCを使用してスタイルを適用
export default withStyles(styles)(Home);
